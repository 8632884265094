import Card from 'react-bootstrap/Card';
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';


function ParticipantCard({ name, race, profession, description, characteristics, abilities, talents }) {
    const [tabSelection, setTabSelection] = useState(0);

    return (
        <Card className="mb-4 shadow-sm bg-custom-secondary mx-auto" text="white">
            <Card.Body>
                <Card.Title className="h5 fw-bold">{name}</Card.Title>
                <Card.Subtitle className="mb-3 text-light-muted">
                    {race} - {profession}
                </Card.Subtitle>

                <Nav
                    variant="tabs"
                    className="mb-3"
                    activeKey={tabSelection}
                    onSelect={(selectedKey) => setTabSelection(Number(selectedKey))}
                >
                    <Nav.Item>
                        <Nav.Link
                            eventKey={0}
                            className={tabSelection === 0 ? 'text-dark fw-bold' : 'text-white'}
                        >
                            General
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            eventKey={1}
                            className={tabSelection === 1 ? 'text-dark fw-bold' : 'text-white'}
                        >
                            Characteristics
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            eventKey={2}
                            className={tabSelection === 2 ? 'text-dark fw-bold' : 'text-white'}
                        >
                            Abilities
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link
                            eventKey={3}
                            className={tabSelection === 3 ? 'text-dark fw-bold' : 'text-white'}
                        >
                            Talents
                        </Nav.Link>
                    </Nav.Item>
                </Nav>

                {tabSelection === 0 && <Card.Text>{description}</Card.Text>}
                {tabSelection === 1 && (
                    <ul className="list-unstyled">
                        {characteristics.map((characteristic, index) => (
                            <li key={index}>
                                <strong>{characteristic.name}:</strong> {characteristic.value}
                            </li>
                        ))}
                    </ul>
                )}
                {tabSelection === 2 && (
                    <ul className="list-unstyled">
                        {abilities.map((ability, index) => (
                            <li key={index}>
                                <strong>{ability.name}:</strong> {ability.value}
                            </li>
                        ))}
                    </ul>
                )}
                {tabSelection === 3 && (
                    <ul className="list-unstyled">
                        {talents.map((talent, index) => (
                            <li key={index}>{talent}</li>
                        ))}
                    </ul>
                )}
            </Card.Body>
        </Card>
    )
}

export default ParticipantCard