import React, { useState } from 'react';
import { Row, Col, Button, Form, Alert, Modal, Container, Card } from 'react-bootstrap';
import api from '../../Api/api';

import mixpanel from 'mixpanel-browser';

import {
    useNavigate
} from "react-router-dom";

export default function Login({ onLoginDone, globalUser }) {

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const [loginError, setLoginError] = useState(false);

    const [noEmailForgot, setNoEmailForgot] = useState(false);

    const [showConfirmReset, setShowConfirmReset] = useState(false);

    const handleEmailChange = (email) => {
        setFormData((state) => ({
            ...state,
            email: email.target.value
        }))
    }

    const handlePasswordChange = (password) => {
        setFormData((state) => ({
            ...state,
            password: password.target.value
        }))
    }

    const handleSubmit = (event) => {
        mixpanel.track('on_login', { email: formData.email });
        event.preventDefault();
        handleLoginSubmit(formData);
    }

    const handleLoginSubmit = (formData) => {
        setLoginError(false);
        api.post(
            '/auth/login',
            { email: formData.email, password: formData.password }
        ).then(response => {
            mixpanel.track('on_login_success', { email: formData.email });
            localStorage.setItem('accessToken', response.data.tokens.access.token);
            localStorage.setItem('refreshToken', response.data.tokens.refresh.token);
            handleLoginDone();
        }).catch(error => {
            mixpanel.track('on_login_error', { email: formData.email });
            console.log(error)
            setLoginError(true);
        });
    }

    const handleForgotPassword = () => {
        mixpanel.track('on_forgot_password');
        if (formData.email) {
            setNoEmailForgot(false);
            api.post('/auth/forgot-password',
                {
                    email: formData.email
                }
            ).then(response => {
                setShowConfirmReset(true);
            });
        } else {
            mixpanel.track('on_forgot_email_empty');
            setNoEmailForgot(true);
        }
    }

    const handleGoToRegister = () => {
        mixpanel.track('go_to_register');
        navigate("/register");
    }

    const handleCloseResetConfirm = () => {
        setShowConfirmReset(false);
    }

    const handleLoginDone = () => {
        onLoginDone();
        if (!globalUser.gameTokens || globalUser.gameTokens == 0) {
            mixpanel.track('buy_more');
            navigate('/buy_more');
        } else if (globalUser.gameTokens > 0) {
            mixpanel.track('game');
            navigate('/game');
        }
    }

    return (
        <>
            <Container
                fluid
                className="d-flex align-items-center justify-content-center min-vh-100"
                style={{ backgroundColor: '#1b262c' }}
            >
                <Card
                    className="p-4 w-100 text-white"
                    style={{ maxWidth: '400px', backgroundColor: '#1b262c', border: 'none' }}
                >
                    <Card.Body>
                        <h2 className="text-center mb-4 text-white">Login</h2>
                        {loginError && <Alert variant="danger">Wrong email or password!</Alert>}
                        {noEmailForgot && <Alert variant="danger">Please enter an email!</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label className="text-white">Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Enter email"
                                    onChange={handleEmailChange}
                                    value={formData.email}
                                    className="bg-dark text-white"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label className="text-white">Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Enter password"
                                    onChange={handlePasswordChange}
                                    value={formData.password}
                                    className="bg-dark text-white"
                                />
                            </Form.Group>
                            <Button variant="success" type="submit" className="w-100 mb-3">
                                Login
                            </Button>
                            <Button variant="link" onClick={handleForgotPassword} className="w-100 text-white">
                                Forgot password?
                            </Button>
                        </Form>
                        <div className="text-center mt-4 d-flex justify-content-center align-items-center">
                            <span className="text-white">Don't have an account?</span>
                            <Button variant="link" onClick={handleGoToRegister} className="p-0 ms-1 text-warning align-baseline">
                                Register
                            </Button>
                        </div>

                    </Card.Body>
                </Card>
            </Container>

            <Modal
                show={showConfirmReset}
                onHide={handleCloseResetConfirm}
                centered
                data-bs-theme="dark"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Password Reset Sent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    An email has been sent to your registered email address with instructions to reset your password.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseResetConfirm}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}