import React, { useState } from 'react';
import ParticipantCard from './ParticipantCard';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/esm/Button';


function Results({ scenarioData, onSubmit, showProceedBtn }) {
    const { participants, description, introduction, players } = scenarioData;

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitEnabled(false);
        onSubmit();
    };

    const [submitEnabled, setSubmitEnabled] = useState(true);

    return (
        <Col className="Results">
            <Row>
                <Col>
                    <h5 className="fw-bold d-inline">Participants:</h5>
                    <p className="d-inline"> {participants}</p>
                </Col>

            </Row>
            <Row>
                <Col>
                    <h5 className="fw-bold mt-4">Scenario Description:</h5>
                    <p className='p-2 fs-6'>{description}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className="fw-bold mt-2">Introduction</h5>
                    <p className='p-2 fs-6'>{introduction}</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        <h5 className="fw-bold mt-2">Players</h5><br />
                        <Row>
                            {players && players.map((item) =>
                                <ParticipantCard
                                    name={item.name}
                                    race={item.race}
                                    profession={item.profession}
                                    description={item.description}
                                    characteristics={item.characteristics}
                                    abilities={item.abilities}
                                    talents={item.talents} />

                            )}
                        </Row>
                    </p>
                </Col>
            </Row>
            <Row>
                {showProceedBtn && <Col md='auto'>
                    <Button variant='success' onClick={handleSubmit} disabled={!submitEnabled}>Proceed</Button>
                </Col>}
            </Row>
        </Col >
    );
}

export default Results;
