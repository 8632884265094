import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import mixpanel from 'mixpanel-browser';

import {
    useNavigate
} from "react-router-dom";
import Image from 'react-bootstrap/esm/Image';

export default function Index({ globalUser, showTryBtn }) {

    const navigate = useNavigate();

    const handleStartGameClick = () => {
        mixpanel.track('start_game');
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
            mixpanel.track('login');
            navigate("/login");
        } else if (globalUser.gameTokens == 0) {
            mixpanel.track('buy_more');
            navigate("/buy_more");
        } else if (globalUser.gameTokens > 0) {
            mixpanel.track('game');
            navigate("/game");
        }
    }

    const handleTryIt = () => {
        mixpanel.track('on_try_it');
        navigate('/game');
    }

    return (
        <Row className="justify-content-md-center mt-4">
            <Col className='justify-content-md-center'>
                {/* Hero Section */}
                <div className="position-relative overflow-hidden hero-section my-0 my-md-5 px-0 px-md-3">
                    <Image
                        src="./img/hero-background.jpg"
                        alt="Hero Background"
                        fluid
                        className="w-100 hero-image"
                    />
                    {/* Glass-like Overlay */}
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-start align-items-md-center hero-overlay"
                    >
                        <Row className="justify-content-start justify-content-md-center mx-0 mb-md-0 mb-4">
                            <Col xs={12} className="text-start text-md-center px-3 px-md-0">
                                <h1 className="fw-bold text-white hero-title">
                                    Your Ultimate Virtual Dungeon Master
                                </h1>
                                <p className="mt-3 fs-5 fs-md-4 text-light-muted hero-subtitle">
                                    Elevate your gaming sessions with our{' '}
                                    <strong className="text-white">GEMINI</strong> AI-powered scenario generator.
                                </p>
                            </Col>
                        </Row>

                        <Row className="mt-4 justify-content-center mx-0 w-100">
                            <Col xs={12} md="auto" className="d-grid gap-2 px-6">
                                <Button
                                    onClick={handleStartGameClick}
                                    variant="success"
                                    size="md"
                                    className="fw-bold shadow-sm"
                                    style={{ padding: '0.5rem 1rem' }}
                                >
                                    PLAY ENDLESS
                                </Button>
                            </Col>
                            {showTryBtn && (
                                <Col xs={12} md="auto" className="mt-3 mt-md-0 d-grid gap-2 px-6">
                                    <Button
                                        onClick={handleTryIt}
                                        variant="outline-light"
                                        size="md"
                                        className="fw-bold shadow-sm"
                                        style={{ padding: '0.5rem 1rem' }}
                                    >
                                        Try it for free!
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>

                <Row className="mt-1 justify-content-md-center align-items-center feature-row">
                    <Col lg="2" md="3" sm="4" xs="12" className="mb-4 mb-lg-0">
                        <div className="feature-image-wrapper rectangle-right">
                            <Image src="./img/bring_to_life.png" fluid className="feature-image" />
                        </div>
                    </Col>
                    <Col lg="4" md="6" sm="8" xs="12" className="feature-text">
                        <h3 className="feature-title">Character Creation</h3>
                        <p className="feature-description">
                            Bring unique heroes and villains to life, complete with backstories, motivations, and abilities.
                        </p>
                    </Col>
                </Row>

                <Row className="mt-5 justify-content-md-center align-items-center feature-row">
                    <Col lg="4" md="6" sm="8" xs="12" className="order-2 order-lg-1 feature-text">
                        <h3 className="feature-title">Detailed Settings</h3>
                        <p className="feature-description">
                            Explore bustling cities, dark dungeons, or fantastical worlds generated in seconds.
                        </p>
                    </Col>
                    <Col lg="2" md="3" sm="4" xs="12" className="order-1 order-lg-2 mb-4 mb-lg-0">
                        <div className="feature-image-wrapper rounded-circle">
                            <Image src="./img/create_city.png" fluid className="feature-image" />
                        </div>
                    </Col>
                </Row>

                <Row className="mt-5 mb-5 justify-content-md-center align-items-center feature-row">
                    <Col lg="2" md="3" sm="4" xs="12" className="mb-4 mb-lg-0">
                        <div className="feature-image-wrapper rectangle-left">
                            <Image src="./img/people.png" fluid className="feature-image" />
                        </div>
                    </Col>
                    <Col lg="4" md="6" sm="8" xs="12" className="feature-text">
                        <h3 className="feature-title">Compelling Plots</h3>
                        <p className="feature-description">
                            Get inspired by plot twists, side quests, and intrigues that will keep your players glued to the table.
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}