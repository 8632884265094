import { Row, Col, Button, Card } from 'react-bootstrap';
import mixpanel from 'mixpanel-browser';

export default function BuyMore({ userId }) {
    const handleBuy5 = () => mixpanel.track('on_buy_5');
    const handleBuy10 = () => mixpanel.track('on_buy_15');
    const handleBuy30 = () => mixpanel.track('on_buy_30');

    return (
        <Row className='justify-content-center p-3 mt-4'>
            <Col xs={12} md={8} className="mt-4">
                {/* Sections: AI Costs & Coin Explanation */}
                <Row className="g-4 mb-5">
                    {/* Section: AI Costs */}
                    <Col xs={12} md={6}>
                        <Card text='light' className="shadow-sm h-100 bg-custom-secondary">
                            <Card.Body className="d-flex flex-column">
                                <Card.Title className="mb-2 fs-5 fw-bold">
                                    Behind the Scenes: AI Costs
                                </Card.Title>
                                <Card.Subtitle className="mb-5 text-light-muted fs-6">
                                    Understanding the Investment
                                </Card.Subtitle>
                                <Card.Text className="flex-grow-1">
                                    Your contribution allows us to utilize advanced AI, providing you with a more engaging and dynamic RPG experience.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Section: Coin Explanation */}
                    <Col xs={12} md={6}>
                        <Card text='light' className="shadow-sm h-100 bg-custom-secondary">
                            <Card.Body className="d-flex flex-column">
                                <Card.Title className="mb-2 fs-5 fw-bold">
                                    1 Coin = 1 Endless Game
                                </Card.Title>
                                <Card.Subtitle className="mb-5 text-light-muted fs-6">
                                    Simple and Transparent
                                </Card.Subtitle>
                                <Card.Text className="">
                                    Each game you play costs <strong>1 Coin</strong>.<br />
                                    Each Coin gives you access to an endless game.<br />
                                    No matter how long it lasts, you will always spend only <strong>1 Coin</strong>!
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Call to Action */}
                <h3 className="text-center mb-3">Unlock new adventures — choose below:</h3>
                <Row className='justify-content-center g-3'>
                    {/* 5 Coins Pack */}
                    <Col xs={12} sm={6} md={4}>
                        <Card text='light' className="h-100 shadow-sm bg-custom-secondary">
                            <Card.Img variant="top" src="./img/free.png" />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title><b>5 Coins Pack</b></Card.Title>
                                <Card.Text>
                                    Try our RPG Master! Small pack for beginners.
                                </Card.Text>
                                <Button
                                    variant="success"
                                    className="mt-auto"
                                    onClick={handleBuy5}
                                    href={`${process.env.REACT_APP_BUY_MORE_5}${userId}`}
                                >
                                    <b>Buy now! 1,99€</b>
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* 15 Coins Pack */}
                    <Col xs={12} sm={6} md={4}>
                        <Card text='light' className="h-100 shadow-sm bg-custom-secondary">
                            <Card.Img variant="top" src="./img/pro.png" />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title><b>15 Coins Pack</b></Card.Title>
                                <Card.Text>
                                    Be a pro! Play more often with your friends.
                                </Card.Text>
                                <Button
                                    variant="success"
                                    className="mt-auto"
                                    onClick={handleBuy10}
                                    href={`${process.env.REACT_APP_BUY_MORE_15}${userId}`}
                                >
                                    <b>Buy now! 4,99€</b>
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    {/* 30 Coins Pack */}
                    <Col xs={12} sm={6} md={4}>
                        <Card text='light' className="h-100 shadow-sm bg-custom-secondary">
                            <Card.Img variant="top" src="./img/pro_super.png" />
                            <Card.Body className="d-flex flex-column">
                                <Card.Title><b>30 Coins Pack</b></Card.Title>
                                <Card.Text>
                                    Master it! For everyday players.
                                </Card.Text>
                                <Button
                                    variant="success"
                                    className="mt-auto"
                                    onClick={handleBuy30}
                                    href={`${process.env.REACT_APP_BUY_MORE_30}${userId}`}
                                >
                                    <b>Buy now! 9,99€</b>
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}