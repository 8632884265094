import React, { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';

function NextMove({ nextMove, onSubmit }) {
    const { instructions, options, puzzle } = nextMove

    const [selection, setSelection] = useState();

    const [hasSubmitted, setHasSubmitted] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        onSubmit(selection);
    };

    const handleChange = (e) => {
        setSelection(e.target.value);
    };

    return (
        <Col>
            <h3 className="fw-bold mb-3">What's your next move?</h3>
            <p className='p-2 fs-6'>{instructions.description}</p>

            {options && options.length > 0 && (
                <div className="mt-4">
                    <h4 className="fw-semibold">Options:</h4>
                    <Form className='p-2 fs-6'>
                        {options.map((option, index) => (
                            <Form.Check
                                type="radio"
                                id={`option-${index}`}
                                key={index}
                                label={option}
                                value={option}
                                name="answer"
                                onChange={handleChange}
                                className="mb-2"
                            />
                        ))}
                    </Form>
                </div>
            )}

            {puzzle && Object.keys(puzzle).length > 0 && (
                <div className="mt-4">
                    <h4 className="fw-semibold">Puzzle:</h4>
                    <p>{puzzle.description}</p>
                    <Form>
                        {puzzle.possible_answrs && puzzle.possible_answrs.map((option, index) => (
                            <Form.Check
                                type="radio"
                                id={`puzzle-option-${index}`}
                                key={index}
                                label={option}
                                value={option}
                                name="answer"
                                onChange={handleChange}
                                className="mb-2"
                            />
                        ))}
                    </Form>
                </div>
            )}

            <Button
                variant="success"
                onClick={handleSubmit}
                disabled={!selection || hasSubmitted}
                className="mt-4 w-100"
            >
                Proceed
            </Button>
        </Col>
    );
}

export default NextMove;