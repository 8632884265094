import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Container, Navbar, Nav } from 'react-bootstrap';
import './App.css';
import api from './Api/api';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";

import LoggedUserHeader from './Components/LoggedUserHeader';
import Button from 'react-bootstrap/esm/Button';

import Index from './Containers/Index'
import Login from './Containers/Login';
import BuyMore from './Containers/BuyMore';
import Game from './Containers/Game';
import Register from './Containers/Register';
import ResetPassword from './Containers/ResetPassword';

import mixpanel from 'mixpanel-browser';
import AnonimousUserHeader from './Components/AnonimuosUserHeader';

function App() {

  useEffect(() => {
    checkUser();
  }, []);

  const [globalUser, setGlobalUser] = useState({
    userId: null,
    isLogged: false,
    gameTokens: 0,
    isOnLastGame: false
  });

  const checkUser = async () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const response = await api.post('/users/me'
      ).catch(error => {
        console.log(error)
      });

      mixpanel.identify(response.data.id);
      localStorage.setItem('userId', response.data.id);
      setGlobalUser((user) => ({
        ...user,
        userId: response.data.id,
        isLogged: true,
        gameTokens: response.data.gameTokens
      }));
    }
  }

  const handleLogindone = () => {
    onAuthDone();
  }

  const handleRegistrationDone = async () => {
    mixpanel.track('registration_done');
    await onAuthDone()
  }

  const handleGameStarted = () => {
    if (globalUser.userId) {
      const lastGame = globalUser.gameTokens == 1
      api.post('/users/decreaseGame').then(response => {
        setGlobalUser((data) => ({
          ...data,
          gameTokens: response.data.gameTokens,
          isOnLastGame: lastGame
        }));
      });
    }
  }

  async function onAuthDone() {
    await checkUser();
  }

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setGlobalUser((data) => ({
      ...data,
      isLogged: false
    }));
  }

  return (
    <Container className="App">
      <Router>
        <div className="d-flex flex-column min-vh-100">
          <Navbar expand="lg" className="py-3 navbar-dark">
            <Container fluid>
              <Navbar.Brand href="/" className="fw-bold text-white">
                RPG Master AI
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="navbar-nav" />
              <Navbar.Collapse id="navbar-nav">
                <Nav className="ms-auto align-items-end mt-4 bg-custom-secondary-mobile p-3 rounded">
                  {globalUser.isLogged ? (
                    <LoggedUserHeader gameTokens={globalUser.gameTokens} onLogout={handleLogout} />
                  ) : (
                    <AnonimousUserHeader />
                  )}
                  <Nav.Item className="my-2 my-lg-0">
                    <Button
                      variant="success"
                      href="https://discord.gg/xPAUh48pqC"
                      size="sm"
                      className="ms-2"
                      target="_blank"
                    >
                      <b>Join us on Discord!</b>
                    </Button>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>

            </Container>
          </Navbar>

          <main className="flex-grow-1">

            <Routes>
              <Route path='/' element={<Index globalUser={globalUser} showTryBtn={!globalUser.userId} />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/login' element={<Login onLoginDone={handleLogindone} globalUser={globalUser} />} />
              <Route path='/register' element={<Register onRegstrationCompleted={handleRegistrationDone} globalUser={globalUser} />} />
              <Route path='/buy_more' element={<BuyMore userId={globalUser.userId} />} />
              <Route path='/game' element={<Game onGameStarted={handleGameStarted} tryMode={!globalUser.userId || (globalUser.userId && (globalUser.gameTokens == 0 && !globalUser.isOnLastGame))} />} />
            </Routes>
          </main>
          <footer className="mt-auto text-center py-4">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col xs={12} md={4} className="mb-3 mb-md-0">
                  <p className="mb-0">
                    Powered by{' '}
                    <a
                      href="https://consultingmine.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-none fw-semibold"
                    >
                      Mine Consulting SRL
                    </a>
                  </p>
                </Col>
                <Col xs={12} md={4} className="mb-3 mb-md-0">
                  <p className="mb-0 text-light-muted">P.IVA: 03917920922</p>
                </Col>
                <Col xs={12} md={4}>
                  <p className="mb-0">
                    <a
                      href="mailto:support@consultingmine.com"
                      className="text-success text-decoration-none fw-semibold"
                    >
                      Need support?
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
          </footer>
        </div>
      </Router>
    </Container >
  );
}
export default App;
