import React, { useState } from 'react';
import { Button, Form, Modal, Card } from 'react-bootstrap';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import ScenarioSuggestionChip from './ScenarioSuggestionChip';


function ScenarioForm({ onSubmit, isTryMode, onTryModeRegister }) {
    const [participants, setNumberOfParticipants] = useState(2);
    const [description, setDescription] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            participants,
            description,
        };
        onSubmit(data);
    };

    const handleSuggestionClick = (desc) => {
        setDescription(desc);
    }

    const handleParticipantsChange = (e) => {
        if (isTryMode) {
            setShowUpgrade(true);
        } else {
            setNumberOfParticipants(e.target.value);
        }
    }

    const [showUpgrade, setShowUpgrade] = useState(false);

    const handleCloseUpgradeDialog = () => {
        setShowUpgrade(false);
    }

    return (
        <>
            <Row className="justify-content-center my-4 mx-0">
                <Col xs={12} md={8} className="px-4">
                    <Card className="bg-transparent text-white border-0">
                        <Card.Body className="p-0">
                            <Form className='mb-5' onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formParticipants">
                                    <Form.Label className="fw-bold fs-5">Participants</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter number of participants"
                                        min="2"
                                        value={isTryMode ? 2 : participants}
                                        onChange={handleParticipantsChange}
                                        disabled={isTryMode}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-4" controlId="formDescription">
                                    <Form.Label className="fw-bold fs-5">Scenario Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Describe your scenario..."
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>

                                <Button
                                    variant="success"
                                    type="submit"
                                    className="w-100 mb-3 btn-lg"
                                    disabled={!participants || !description}
                                >
                                    Generate
                                </Button>
                            </Form>

                            <h5 className="text-center mb-3 fw-semibold">Or choose a suggestion:</h5>
                            <Row className="g-3">
                                <Col xs={12}>
                                    <ScenarioSuggestionChip
                                        onClick={handleSuggestionClick}
                                        label="We are a group of researchers trying to find the origins of life in the Nevada desert..."
                                        image="./img/nevada_scenario.jpg"
                                    />
                                </Col>
                                <Col xs={12}>
                                    <ScenarioSuggestionChip
                                        onClick={handleSuggestionClick}
                                        label="We are in a plane under attack by some cyber-criminals..."
                                        image="./img/airplane_scenario.jpg"
                                    />
                                </Col>
                                <Col xs={12}>
                                    <ScenarioSuggestionChip
                                        onClick={handleSuggestionClick}
                                        label="We find ourselves shipwrecked on a mysterious, uncharted island..."
                                        image="./img/island_scenario.jpg"
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showUpgrade} onHide={handleCloseUpgradeDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>This function is not available in try mode.</Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={onTryModeRegister}>
                        Register
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ScenarioForm;


