import React from 'react';
import { Nav, Button, Image } from 'react-bootstrap';

import mixpanel from 'mixpanel-browser';

import {
    useNavigate
} from "react-router-dom";

function LoggedUserHeader({ gameTokens, onLogout }) {

    const navigate = useNavigate();

    const handleLogout = () => {
        mixpanel.track('logout');
        onLogout();
        navigate('/');
    }

    const handleBuyMoreClick = () => {
        mixpanel.track('on_buy_more');
        navigate('/buy_more');
    }

    return (
        <>
            <Nav.Item className="my-2 my-lg-0">
                <div className="d-flex flex-column flex-lg-row align-items-end align-items-lg-center">
                    <div className="d-flex align-items-center">
                        <Image src="./img/coin.png" width="24" height="24" className="me-1" />
                        <span className="me-2 text-white">Coins: {gameTokens}</span>
                    </div>
                    <div className="d-flex flex-column flex-lg-row">
                        <Button
                            variant="outline-light"
                            size="sm"
                            onClick={handleBuyMoreClick}
                            className="mt-3 mt-lg-0 ms-0 ms-lg-2"
                        >
                            Buy More
                        </Button>
                        <Button
                            variant="outline-light"
                            size="sm"
                            onClick={handleLogout}
                            className="mt-3 mt-lg-0 ms-0 ms-lg-2"
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            </Nav.Item>
        </>
    );
}

export default LoggedUserHeader;