import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

function MaxTryReached({ onRegister }) {
    return (
        <>
            <Col>
                <Row>
                    <h3>Did you like RPGMaster?</h3>
                    <p>We really appreciate that you wanted to test our service.<br />
                        Test mode only allows you to try the game.
                        <br />If you like it, create an account and start playing with your friends.</p>
                </Row>
                <Row className="ms-1 mb-2" md='auto'><Button onClick={onRegister} variant="success">Register</Button></Row>
            </Col>
        </>
    )
}

export default MaxTryReached;