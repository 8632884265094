import Card from 'react-bootstrap/Card';

export default function ScenarioSuggestionChip({ label, image, onClick }) {
    return (
        <Card
            className="text-white w-100 border-0 suggestion-chip-card"
            onClick={() => onClick(label)}
        >
            <div className="suggestion-chip-card-image-container">
                <Card.Img
                    src={image}
                    alt="Scenario image"
                    className="suggestion-chip-card-image"
                />
                <Card.ImgOverlay className="d-flex flex-column justify-content-center p-3 suggestion-chip-card-overlay">
                    <Card.Text className="text-center fs-6 fw-bold mb-0 suggestion-chip-card-text">
                        {label}
                    </Card.Text>
                </Card.ImgOverlay>
            </div>
        </Card>
    );
}