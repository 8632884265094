import { useState } from "react";
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/esm/Col';
import Alert from 'react-bootstrap/Alert';
import api from "../../Api/api";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function ResetPassword() {
    const [params] = useSearchParams();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const [passwordMismatch, setPasswordMismatch] = useState(false);

    const handlePasswordChange = (event) => {
        setFormData((data) => ({
            ...data,
            password: event.target.value
        }));
    }

    const handleConfirmPasswordChange = (event) => {
        setFormData((data) => ({
            ...data,
            confirmPassword: event.target.value
        }));
    }

    const handleSubmitForm = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() == false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (formData.password != formData.confirmPassword) {
            event.preventDefault();
            event.stopPropagation();
            setPasswordMismatch(true)
        }

        resetPassword();
    }

    const navigate = useNavigate();

    function resetPassword() {
        api.post('/auth/reset-password?token=' + params.get("token"),
            {
                password: formData.password
            }
        ).then(response => {
            navigate('/')
        }).catch((e) => {
            console.log(e);
        });
    }

    return (
        <Row>
            <Col>
                <Row className="justify-content-md-center">
                    <Col md="auto">
                        <Form onSubmit={handleSubmitForm}>
                            <Row>
                                <Col md="auto">
                                    <Form.Group className="mb-3" controlId="formPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control required type="password" placeholder="Enter password" onChange={handlePasswordChange} value={formData.password} />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formConfirmPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control required type="password" placeholder="Enter password" onChange={handleConfirmPasswordChange} value={formData.confirmPassword} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col md="auto">
                                    <Button variant="success" type="submit">
                                        Change password
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-md-center mt-4">
                    <Col md="auto">
                        {passwordMismatch && <Alert variant="danger">Passwords doesn't match</Alert>}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}