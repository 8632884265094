import mixpanel from 'mixpanel-browser';
import { Nav, Button, Image } from 'react-bootstrap';

import {
    useNavigate
} from "react-router-dom";

function AnonimousUserHeader() {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        mixpanel.track('go_to_login');
        navigate('/login');
    }

    return (
        <>
            <Nav.Item className="my-2 my-lg-0">
                <Button variant="outline-light" size="sm" onClick={handleLoginClick} className="ms-2">
                    Login
                </Button>
            </Nav.Item>
        </>
    );
}

export default AnonimousUserHeader;