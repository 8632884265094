import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

function LoadingComponent({ loadingText }) {
    return (
        <Row className="justify-content-md-center align-items-center">
            <Col md="auto">
                <Spinner animation="grow" variant="success" role="status" />
            </Col>
            <Col md="auto">
                <span className="text-white"><b>{loadingText}</b></span>
            </Col>
        </Row>
    );
}

export default LoadingComponent;