import { useState } from "react";
import { Button, Form, Alert, Modal, Container, Card } from 'react-bootstrap';
import api from "../../Api/api";

import mixpanel from 'mixpanel-browser';

import {
    useNavigate
} from "react-router-dom";

export default function Register({ onRegstrationCompleted, globalUser }) {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
    });

    const [passwordMismatch, setPasswordMismatch] = useState(false);

    const [registerError, setRegisterError] = useState(null);

    const handleEmailChange = (event) => {
        setFormData((data) => ({
            ...data,
            email: event.target.value
        }));
    }

    const handlePasswordChange = (event) => {
        setFormData((data) => ({
            ...data,
            password: event.target.value
        }));
    }

    const handleConfirmPasswordChange = (event) => {
        setFormData((data) => ({
            ...data,
            confirmPassword: event.target.value
        }));
    }

    const handleNameChange = (event) => {
        setFormData((data) => ({
            ...data,
            name: event.target.value
        }));
    }

    const handleSubmitForm = (event) => {
        mixpanel.track('on_register_submit', { email: formData.email });
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() == false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (formData.password != formData.confirmPassword) {
            event.preventDefault();
            event.stopPropagation();
            setPasswordMismatch(true)
            return;
        } else {
            setPasswordMismatch(false);
        }

        registerUser();
    }

    function registerUser() {
        api.post(
            '/auth/register',
            { email: formData.email, password: formData.password, name: formData.name }
        ).then(response => {
            mixpanel.track('on_register_success', { email: formData.email });
            localStorage.setItem('accessToken', response.data.tokens.access.token);
            localStorage.setItem('refreshToken', response.data.tokens.refresh.token);
            handleRegistrationDone();
        }).catch(error => {
            mixpanel.track('on_register_error', { email: formData.email, error: error.response.data.message });
            console.log(error.response.data.message);
            setRegisterError(error.response.data.message);
        });
    }

    const handleCloseResetConfirm = () => {
        setRegisterError(null);
    }

    const handleRegistrationDone = async () => {
        await onRegstrationCompleted();
        if (!globalUser.gameTokens || globalUser.gameTokens == 0) {
            mixpanel.track('buy_more');
            navigate('/buy_more');
        } else if (globalUser.gameTokens > 0) {
            mixpanel.track('game');
            navigate('/game');
        }
    }

    const handleGoToLogin = () => {
        navigate("/login");
    };

    return (
        <>
            <Container
                fluid
                className="d-flex align-items-center justify-content-center min-vh-100"
                style={{ backgroundColor: '#1b262c' }}
            >
                <Card
                    className="p-4 w-100 text-white border-0"
                    style={{ maxWidth: '400px', backgroundColor: '#1b262c' }}
                >
                    <Card.Body>
                        <h2 className="text-center mb-4">Register</h2>
                        {passwordMismatch && <Alert variant="danger">Passwords don't match!</Alert>}
                        {registerError && <Alert variant="danger">{registerError}</Alert>}
                        <Form noValidate onSubmit={handleSubmitForm}>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter name"
                                    onChange={handleNameChange}
                                    value={formData.name}
                                    className="bg-dark text-white"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Enter email"
                                    onChange={handleEmailChange}
                                    value={formData.email}
                                    className="bg-dark text-white"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Enter password"
                                    onChange={handlePasswordChange}
                                    value={formData.password}
                                    className="bg-dark text-white"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Confirm password"
                                    onChange={handleConfirmPasswordChange}
                                    value={formData.confirmPassword}
                                    className="bg-dark text-white"
                                />
                            </Form.Group>
                            <Button variant="success" type="submit" className="w-100 mb-3">
                                Register
                            </Button>
                            <div className="text-center mt-4">
                                <span className="text-white align-middle">
                                    Already have an account?
                                </span>
                                <Button
                                    variant="link"
                                    onClick={handleGoToLogin}
                                    className="p-0 ms-1 text-warning align-baseline"
                                    style={{ display: 'inline' }}
                                >
                                    Login
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>

            {/* Modal */}
            <Modal
                show={registerError !== null}
                onHide={handleCloseResetConfirm}
                centered
                data-bs-theme="dark"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{registerError}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseResetConfirm}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}