import { useState, useEffect } from "react";
import ScenarioForm from "../../Components/ScenarioForm";
import Results from "../../Components/Results";
import NextMove from "../../Components/NextMove";
import io from 'socket.io-client';

import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/esm/Col";
import LoadingComponent from "../../Components/LoadingComponent";
import Row from "react-bootstrap/esm/Row";
import MaxTryReached from "../../Components/MaxTryReached";
import Alert from "react-bootstrap/esm/Alert";

import mixpanel from 'mixpanel-browser';

import {
    useNavigate
} from "react-router-dom";


const socket = io(process.env.REACT_APP_BASE_SOCKET_URL);

export default function Game({ onGameStarted, tryMode }) {

    const navigate = useNavigate();

    /**
     * Statuses are ordered!
     */
    const Status = {
        IDLE: 0,
        STARTING_GAME: 1,
        SCENARIO_FORM: 2,
        LOADING_SCENARIO: 3,
        SHOWING_RESULTS: 4,
        NEXT_MOVE: 5,
        LOADING_NEXT_MOVE: 6,
        REACH_MAX_TRY: 7
    }

    const [conversationId, setConversationId] = useState('');

    const [scenarioData, setScenarioData] = useState({
        participants: 0,
        description: null,
        introduction: null,
        players: null,
        status: Status.IDLE,
        lastChoice: null
    });

    const [nextMove, setNextMove] = useState({});

    const [movesCount, setMovesCount] = useState(0);

    useEffect(() => {
        socket.on('conversationStarted', (id) => {
            setConversationId(id)
            setScenarioData(data => ({
                ...data,
                status: Status.SCENARIO_FORM
            }));
        })
        socket.on('gameStarted', (scenario) => {
            const objScenario = JSON.parse(scenario)
            setScenarioData(prevState => ({
                ...prevState,
                introduction: objScenario.introduction,
                players: objScenario.players,
                status: Status.SHOWING_RESULTS
            }))
            onGameStarted();
        })
        socket.on('onNextMove', (move) => {
            try {
                const objNextMove = JSON.parse(move);
                setScenarioData(data => ({
                    ...data,
                    status: Status.NEXT_MOVE
                }));
                setNextMove(objNextMove)
            } catch (e) {
                console.log(e);
                /**
                 * Sometimes there is an error when trying to parse json
                 * generated by AI.
                 * For now we just ask server to repeat procedure.
                 */
                setScenarioData(data => ({
                    ...data,
                    status: Status.NEXT_MOVE
                }));
            }
        })
    }, []);

    /**
   * Used to start conversation with socket
   */
    const handleStartConversation = () => {
        mixpanel.track('on_start_conversation');
        socket.emit('startConversation');
        setScenarioData(data => ({
            ...data,
            status: Status.STARTING_GAME,
        }));
    };

    const handleScenarioSubmit = (data) => {
        mixpanel.track('on_scenario_submit');
        setScenarioData({
            participants: data.participants,
            description: data.description,
            status: Status.LOADING_SCENARIO
        })
        socket.emit('startGame', conversationId, data)
    };

    const handleQuestionsSubmit = async (data) => {
        mixpanel.track('on_submit_answer');
        socket.emit('proceed', conversationId);
    }

    const handleNextMove = (choice) => {
        mixpanel.track('on_next_move');
        setMovesCount(movesCount + 1);
        const reachedMaxCount = tryMode && movesCount > 10;
        setScenarioData(data => ({
            ...data,
            status: reachedMaxCount ? Status.REACH_MAX_TRY : Status.LOADING_NEXT_MOVE,
            lastChoice: choice
        }));

        if (!reachedMaxCount) {
            socket.emit('nextMove', conversationId, choice);
        }
    }

    const handleRegisterFromTestMode = () => {
        mixpanel.track('on_register_from_test');
        navigate('/register');
    }

    return (
        <Col className="mt-5">
            {tryMode && (
                <Row className="justify-content-center mt-3">
                    <Col xs="auto">
                        <Alert variant="warning" className="text-center py-2 px-4 fw-bold shadow-sm">
                            Test Mode
                        </Alert>
                    </Col>
                </Row>
            )}

            <Row className="justify-content-center mt-4"><Col md="auto" className="text-center">{scenarioData.status == Status.IDLE && <Button variant="success" onClick={handleStartConversation}>START GAME</Button>}</Col></Row>
            <Row className="justify-content-center mt-4"><Col md="auto" className="text-center">{scenarioData.status == Status.STARTING_GAME && <LoadingComponent loadingText='Starting new session...' />}</Col></Row>
            <Row className="mt-3">{scenarioData.status == Status.SCENARIO_FORM && <ScenarioForm onSubmit={handleScenarioSubmit} isTryMode={tryMode} />}</Row>
            <Row className="justify-content-center mt-3"><Col md="auto" className="text-center">{scenarioData.status == Status.LOADING_SCENARIO && <LoadingComponent loadingText='Creating game session...' />}</Col></Row>
            <Row className="mt-3">{scenarioData.status >= Status.SHOWING_RESULTS && <Results scenarioData={scenarioData} onSubmit={handleQuestionsSubmit} showProceedBtn={scenarioData.status != Status.NEXT_MOVE} />}</Row>
            <Row className="mt-3">{scenarioData.status == Status.NEXT_MOVE && <NextMove nextMove={nextMove} onSubmit={handleNextMove} />}</Row>
            <Row className="mt-3">{scenarioData.status == Status.LOADING_NEXT_MOVE && <LoadingComponent loadingText='Loading next move...' />}</Row>
            <Row className="mt-3">{scenarioData.status == Status.REACH_MAX_TRY && <MaxTryReached onRegister={handleRegisterFromTestMode} />}</Row>
        </Col>
    );
}